import React from "react";

const Earth = (props) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
          enableBackground: "new 0 0 122.88 122.88",
        }}
        viewBox="0 0 122.88 122.88"
        {...props}
      >
        <path
          d="M61.44 0c33.93 0 61.44 27.51 61.44 61.44 0 33.93-27.51 61.44-61.44 61.44C27.51 122.88 0 95.37 0 61.44 0 27.51 27.51 0 61.44 0z"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            fill: "#2394e0",
          }}
        />
        <path
          d="M11.76 93.18a57.678 57.678 0 0 1-7.92-18.23l10.82 5.22.06 3.23c0 1.19-2.02 3.71-2.67 4.67l-.29 5.11zM78.31 6.5c20.62 6.85 36.09 25 39.07 47.1l-1.95-.21c-.35 1.5-.67 1.53-.67 3.33 0 1.59 2 2.65 2 6 0 .9-2.11 2.69-2.2 3.01l-3.13-3.67v4.67l-.47-.02-.84-8.62-1.75.55-2.08-6.41-6.85 7.16-.08 5.24-2.24 1.5-2.38-13.44-1.42 1.04-3.22-4.35-4.81.14-1.84-2.11-1.89.52-3.71-4.25-.72.49 2.3 5.88h2.67v-1.33h1.33c.96 2.66 2 1.08 2 2.67 0 5.55-6.85 9.63-11.34 10.67.24 1 .15 2 1.33 2 2.51 0 1.26-.44 4-.67-.13 5.67-6.5 12.44-9.22 16.65l1.22 8.69c.32 1.89-3.92 3.88-5.36 6.01l.69 3.33-1.95.79c-.34 3.42-3.66 7.21-7.39 7.21h-4c0-4.68-3.34-11.37-3.34-14.68 0-2.81 1.33-3.19 1.33-6.67 0-3.22-3.33-7.83-3.33-8.67v-5.34H45.4c-.4-1.49-.15-2-2-2h-.67c-2.91 0-2.42 1.33-5.34 1.33h-2.67c-2.41 0-6.67-7.72-6.67-8.67v-8c0-3.45 3.16-7.21 5.34-8.67v-3.33l3-3.05 1.67-.28c3.58 0 3.15-2 5.34-2h6v4.67l6.6 2.82.62-2.85c2.99.7 3.77 2.03 7.45 2.03h1.33c2.53 0 2.67-3.36 2.67-6l-5.34.53-2.33-5.06-2.31.61c.42 1.81.64 1.06.64 2.59 0 .9-.74 1-1.34 1.33l-2.31-5.86-4.97-3.55-.66.65 4.23 4.45c-.56 1.6-.63 6.21-2.96 2.98l2.18-1.05-5.44-5.7-3.26 1.27-3.22 3.08c-.34 2.48-1.01 3.73-3.61 3.73-1.73 0-.69-.45-3.34-.67v-6.67h6l-1.95-4.44-.72.44v-1.34l9.75-4.49c-.18-1.4-.41-.65-.41-2.18 0-.09.65-1.32.67-1.34l2.52 1.57-.6-2.87-3.89.8-.72-3.49c3.08-1.62 9.87-7.34 12.03-7.34h2c2.11 0 7.75 2.08 8.67 3.33l-5.35-.54L66 10.75l.38-1.4 2.96-.81.04-1.85h1.34v2l7.59-2.19z"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            fill: "#a1e367",
          }}
        />
      </svg>
    </>
  );
};

export default Earth;
