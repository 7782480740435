export const indianStates = [
  { label: "Andhra Pradesh", value: 0 },
  { label: "Arunachal Pradesh", value: 1 },
  { label: "Assam", value: 2 },
  { label: "Bihar", value: 3 },
  { label: "Chhattisgarh", value: 4 },
  { label: "Goa", value: 5 },
  { label: "Gujarat", value: 6 },
  { label: "Haryana", value: 7 },
  { label: "Himachal Pradesh", value: 8 },
  { label: "Jharkhand", value: 9 },
  { label: "Karnataka", value: 10 },
  { label: "Kerala", value: 11 },
  { label: "Madhya Pradesh", value: 12 },
  { label: "Maharashtra", value: 13 },
  { label: "Manipur", value: 14 },
  { label: "Meghalaya", value: 15 },
  { label: "Mizoram", value: 16 },
  { label: "Nagaland", value: 17 },
  { label: "Odisha", value: 18 },
  { label: "Punjab", value: 19 },
  { label: "Rajasthan", value: 20 },
  { label: "Sikkim", value: 21 },
  { label: "Tamil Nadu", value: 22 },
  { label: "Telangana", value: 23 },
  { label: "Tripura", value: 24 },
  { label: "Uttar Pradesh", value: 25 },
  { label: "Uttarakhand", value: 26 },
  { label: "West Bengal", value: 27 },
  { label: "Andaman and Nicobar Islands", value: 28 },
  { label: "Chandigarh", value: 29 },
  { label: "Dadra and Nagar Haveli and Daman and Diu", value: 30 },
  { label: "Lakshadweep", value: 31 },
  { label: "Delhi", value: 32 },
  { label: "Puducherry", value: 33 },
];
